<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Use the IR spectrum and NMR data below to answer the questions and identify the structure of
        a molecule with molecular formula
        <stemble-latex content="$\ce{C8H16O}$." />
      </p>
      <img style="max-width: 600px" class="mb-4" src="/img/assignments/lutherSpectrumGraph.png" />

      <p class="mb-2">What is the degree of unsaturation?</p>

      <calculation-input v-model="inputs.n1" :disabled="!allowEditing" class="mb-3" />

      <p class="mb-2">What functional group(s) can you identify from the IR spectrum?</p>
      <s-textarea v-model="inputs.sr1" class="mb-4" outlined />

      <p class="mb-2">
        Complete the
        <stemble-latex content="$^1$H" />
        NMR data table below:
      </p>
      <table>
        <tr>
          <th>Signal</th>
          <th>Chemical shift</th>
          <th>Integration</th>
          <th>Multiplicity</th>
          <th>Proposed identity</th>
          <th># Neighbors</th>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{H}_\text{e}$" />
          </td>
          <td>0.90</td>
          <td>6H</td>
          <td>triplet</td>
          <td>
            <s-textarea v-model="inputs.sr2" style="max-width: 200px" outlined />
          </td>
          <td>
            <calculation-input
              v-model="inputs.n2"
              :disabled="!allowEditing"
              style="max-width: 200px"
            />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{H}_\text{d}$" />
          </td>
          <td>1.31</td>
          <td>4H</td>
          <td>sextet</td>
          <td>
            <s-textarea v-model="inputs.sr3" style="max-width: 200px" outlined />
          </td>
          <td>
            <calculation-input v-model="inputs.n3" :disabled="!allowEditing" />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{H}_\text{c}$" />
          </td>
          <td>1.58</td>
          <td>4H</td>
          <td>quartet</td>
          <td>
            <s-textarea v-model="inputs.sr4" style="max-width: 200px" outlined />
          </td>
          <td>
            <calculation-input
              v-model="inputs.n4"
              :disabled="!allowEditing"
              style="max-width: 200px"
            />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{H}_\text{b}$" />
          </td>
          <td>2.34</td>
          <td>1H</td>
          <td>sextet</td>
          <td>
            <s-textarea v-model="inputs.sr5" style="max-width: 200px" outlined />
          </td>
          <td>
            <calculation-input
              v-model="inputs.n5"
              :disabled="!allowEditing"
              style="max-width: 200px"
            />
          </td>
        </tr>

        <tr>
          <td>
            <stemble-latex content="$\text{H}_\text{a}$" />
          </td>
          <td>9.72</td>
          <td>1H</td>
          <td>doublet</td>
          <td>
            <s-textarea v-model="inputs.sr6" style="max-width: 200px" outlined />
          </td>
          <td>
            <calculation-input
              v-model="inputs.n6"
              :disabled="!allowEditing"
              style="max-width: 200px"
            />
          </td>
        </tr>
      </table>

      <p class="mb-1">
        <stemble-latex content="$^{13}$C" />
        NMR Data:
      </p>
      <p class="mb-2">14.1, 20.0, 29.9, 50.3, 204.1</p>

      <p class="mb-2">Is symmetry present?</p>
      <v-radio-group v-model="inputs.m2" class="ml-6 mb-4" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.text" :value="option.value" class="my-1">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Upload a drawing of your proposed molecular structure below:</p>
      <p class="mb-n3"><b>Accepted file types</b>: JPG, JPEG, and PNG</p>

      <v-file-input v-model="attachments" multiple />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Chem241LutherSpectralProblem1',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        n1: null,
        sr1: null,
        n2: null,
        sr2: null,
        n3: null,
        sr3: null,
        n4: null,
        sr4: null,
        n5: null,
        sr5: null,
        n6: null,
        sr6: null,
        m2: null,
      },
      attachments: [],
      options: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
    };
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  margin-bottom: 30px;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
